import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form } from "formik";
import Box from "@mui/material/Box";
import Config from "../ConfigHelper";
import Button from "@mui/material/Button";
import { withRouter, useParams } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TimerIcon from "@mui/icons-material/Timer";
import ActionButton from "./ActionButton";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
const API_URL = Config.getApiURL();

async function getAction(id) {
  console.log("refetch with filter:  " + id);
  const { data } = await axios.get(
    API_URL + "/actions/" + id + ".json?api=true&key=61-61-61-61&"
  );
  return data;
}

function ActionDetail(props) {
  let { id } = useParams();
  const [showDetail, setShowDetail] = useState(false);

  console.log(id);
  if (!id) {
    id = props.id;
  }
  console.log(id);

  const mutation = useMutation((formData) => {
    return axios.post(API_URL + "/completions", formData);
  });

  const { data, error, isError, isSuccess, isLoading } = useQuery(
    ["action"],
    () => (typeof id !== "undefined" ? getAction(id) : {})
  );

  if (mutation.isLoading) return "Saving...";

  if (mutation.isError)
    return <div>An error occurred: {mutation.error.message}</div>;

  if (isLoading) return "loading...";
  if (isError) return error;
  if (isSuccess)
    return (
      <Box p={1}>
        {mutation.isSuccess ? <div>Saved!</div> : null}
        <Grid container>
          <Grid item>
            <Link to={"/edit-action/" + data.id}>
              <Button variant="text" size="small">
                <EditIcon />
                Edit
              </Button>
            </Link>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                window.open(
                  "https://app.forestadmin.com/Rhythm/Production/Operations/data/Action/index/record/Action/" +
                    data.id +
                    "/details"
                );
              }}
            >
              <EditIcon />
              Edit Forest
            </Button>
          </Grid>
          <Grid item>
            <ActionButton
              id={id}
              completed={true}
              label="Complete"
              setDone={props.setDone}
              setCelebrate={props.setCelebrate}
              done={props.done}
              inner={CheckBoxOutlineBlankIcon}
            >
              <CheckBoxOutlineBlankIcon />
            </ActionButton>
          </Grid>
          <Grid item>
            <ActionButton
              id={id}
              completed={false}
              label="Skip"
              setDone={props.setDone}
              setCelebrate={props.setCelebrate}
              done={props.done}
              inner={CancelPresentationIcon}
            />
          </Grid>
          <Grid item>
            {props.showTimer && !props.wallDisplay && (
              <Grid item>
                <Button onClick={() => props.handleTimer(data)}>
                  <TimerIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {data.label}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div>
            I {data.mandatory ? "will " : "may "}
            {data.label}
            <br />
            {data.scheduleType === "daily" && `every day at ${data.hourDue}`}
            {data.scheduleType === "hour" && `every ${data.hourDue} hours`}
            {data.scheduleType !== "daily" &&
              data.scheduleType !== "hour" &&
              `every ${data.hourDue} ${data.scheduleType}`}
            <br />
            on these days of the week {data.days_array}
            <br />
            {data.startHour &&
              `I can do this as early as ${data.startHour}<br/>`}
            {data.endHour && `I cannot do this after ${data.endHour}<br/>`}
            {data.estimated_mins &&
              `this will take about ${data.estimated_mins} minutes`}
            <br />
            this is {data.importance} in importance
            <br />
            this is tagged with {data.domain_list}
            <br />I will do this at {data.link_url}
            <br />
          </div>
        </Typography>

        <Button onClick={() => setShowDetail((prev) => !prev)}>
          {showDetail ? "Hide Detail" : "Show Detail"}
        </Button>
        {showDetail && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
          </Typography>
        )}
      </Box>
    );
}

export default ActionDetail;
