import React from "react";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import Config from "../ConfigHelper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import TimerIcon from "@mui/icons-material/Timer";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const API_URL = Config.getApiURL();

const queryClient = new QueryClient();

function DidItButtonGroup(props) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const mutation = useMutation((formData) => {
    return axios.post(
      API_URL + "/completions?api=true&key=61-61-61-61&",
      formData
    );
  });

  if (props.completed) return "Done";

  return (
    <div>
      <div>
        {mutation.isLoading ? (
          "Saving..."
        ) : (
          <>
            {mutation.isError ? (
              <div>An error occurred: {mutation.error.message}</div>
            ) : null}

            {/* {mutation.isSuccess ? <div>Congrats here</div> : null} */}
            {!mutation.isSuccess && (
              <div>
                <div>
                  <Formik
                    initialValues={{
                      completion: {
                        action_id: props.id,
                        status: 1, //completed
                      },
                    }}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {
                        alert(
                          JSON.stringify(values["completion"]["action_id"])
                        );
                        props.handleCompletion(
                          values["completion"]["action_id"]
                        );
                        mutation.mutate(values);
                        // alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                      }, 1000);
                    }}
                  >
                    <Form>
                      <Button type="submit" variant="text">
                        <RadioButtonUncheckedIcon />
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div>
        <Button
          to={"/action/" + props.id}
          component={Link}
          variant="text"
          size="small"
        >
          More
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item>
                <Link to={"/edit-action/" + props.id}>
                  <Button variant="text" size="small">
                    <EditIcon />
                    Edit
                  </Button>
                </Link>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    window.open(
                      "https://app.forestadmin.com/Rhythm/Production/Operations/data/Action/index/record/Action/" +
                        props.id +
                        "/details"
                    );
                  }}
                >
                  <EditIcon />
                  Edit Forest
                </Button>
              </Grid>
              <Grid item>
                <Formik
                  initialValues={{
                    completion: {
                      action_id: props.id,
                      status: 0, //skipped
                    },
                  }}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      mutation.mutate(values);
                      actions.setSubmitting(false);
                    }, 1000);
                  }}
                >
                  <Form>
                    <Button size="small" variant="text" type="submit">
                      <SkipNextIcon />
                      Skip
                    </Button>
                  </Form>
                </Formik>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    props.startTimer(props.id);
                  }}
                >
                  <TimerIcon />
                  Timer
                </Button>
              </Grid>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {props.action.label}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                <pre>{JSON.stringify(props.action, null, 2)}</pre>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default DidItButtonGroup;
