import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Config from "../ConfigHelper";
import Button from "@mui/material/Button";
import ActionRow from "./ActionRow";
import {
  withinHourInterval,
  withinDayInterval,
  shouldAppear,
} from "./DisplayFilters";

import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import Moment from "moment";
import { AlternateEmailTwoTone } from "@mui/icons-material";
const API_URL = Config.getApiURL();

const queryClient = new QueryClient();

// stylesw

// display rules

// filters

function isWithinInterval(value, startHour, endHour) {
  if (startHour === null || endHour === null) {
    return true;
  } else if (value >= startHour && value <= endHour) {
    return true;
  } else {
    return false;
  }
}

const DONE_MSG = [
  "Nice job",
  "Crushing it",
  "Way to go",
  "You da man",
  "That's how we do it",
  "Another vote for the person you want to be",
];

const POINTS = ["2", "5", "10", "100", "1000"];

function getPoints() {
  return POINTS[Math.floor(Math.random() * POINTS.length)];
}

function cardStyles() {
  return {
    ondeck: {
      borderLeft: "8px solid orange",
      //color: "#fff",
      // background:
      //   //"radial-gradient(circle,  0%, rgba(252,70,107,1) 100%)",
      //   "linear-gradient(333deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(63,94,251,1) 100%)",
    },
    overdue: {
      borderLeft: "8px solid red",
      // color: "rgba(255, 255, 255, 0.9)",
      // background:
      //   "linear-gradient(333deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    ok: {
      borderLeft: "8px solid green",
      // color: "rgba(0, 0, 0, 0.7)",
      // background:
      //   "radial-gradient(circle, rgba(28,172,120,1) 44%, rgba(39,101,78,1) 81%);",
    },
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(10, 10, 10, 0.1)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const List = (props) => {
  const [show, setShow] = useState(true);

  function startTimer(action) {
    props.setCurrentAction(action);
    props.setTimerActive(true);
    setShow(false);
  }

  function handleFilter(val) {
    props.setFilter(val);
    props.setDomains("work");
  }

  Moment.locale("en");

  let now = new Date();

  if (!props.loaded) return "Loading...";

  return (
    <div>
      <Grid container>
        <Grid item>
          <h3>
            <Button onClick={() => setShow((prev) => !prev)}>
              {props.heading}
            </Button>
          </h3>
        </Grid>
      </Grid>
      {show && (
        <Grid container spacing={1}>
          {props.actions.filter(props.filterFunc).map((action) => {
            return (
              <ActionRow
                action={action}
                action_id={action.action.id}
                visible={isWithinInterval(
                  now.getHours(),
                  action.action.startHour,
                  action.action.endHour
                )}
                done={props.done}
                setDone={props.setDone}
                updateAction={props.updateAction}
                showTimeDue={["hour", "daily", "day", "month"].includes(
                  action.action.scheduleType
                )}
                setCelebrate={props.setCelebrate}
                showIcon={!props.compact}
                showTime={!props.compact}
                showTags={!props.compact}
                editMode={props.editMode}
                showLink={true}
                setCurrentAction={props.setCurrentAction}
                showTimer={!props.compact}
                wallDisplay={props.compact}
                handleTimer={startTimer}
                {...props}
              />
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default List;
