import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Config from "../ConfigHelper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";

import APIHelper from "../APIHelper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { timeDue } from "../utils";
import ReactTimeAgo from "react-time-ago";
import CardIcon from "./CardIcon";
import { Formik, Field, Form } from "formik";
import { useFormikContext } from "formik";
import DidItButtonGroup from "./DidItButtonGroup";
import { makeStyles } from "@mui/styles";
import Timer from "./Timer";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import Moment from "moment";
const API_URL = Config.getApiURL();

const queryClient = new QueryClient();

const DONE_MSG = [
  "Nice job",
  "Crushing it",
  "Way to go",
  "You da man",
  "That's how we do it",
];

const POINTS = ["2", "5", "10", "100", "1000"];

function getPoints() {
  return POINTS[Math.floor(Math.random() * POINTS.length)];
}

function cardStyles() {
  return {
    ondeck: {
      color: "#fff",
      background:
        //"radial-gradient(circle,  0%, rgba(252,70,107,1) 100%)",
        "linear-gradient(333deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(63,94,251,1) 100%)",
    },
    overdue: {
      color: "rgba(255, 255, 255, 0.9)",
      background:
        "linear-gradient(333deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
    },
    ok: {
      color: "rgba(0, 0, 0, 0.7)",
      background:
        "radial-gradient(circle, rgba(28,172,120,1) 44%, rgba(39,101,78,1) 81%);",
    },
  };
}

const SimpleList = (props) => {
  const classes = makeStyles(cardStyles)();
  const [done, setDone] = useState([]);
  const [show, setShow] = useState(true);
  const [timerActive, setTimerActive] = useState(false);

  const queryClient = useQueryClient();
  const now = Moment.now;

  async function fetchTodos(filter, domain) {
    console.log("refetch with filter:  " + filter);
    const { data } = await axios.get(
      API_URL +
        "/actions.json?api=true&key=61-61-61-61&filter=" +
        filter +
        "&domains=" +
        domain
    );
    return data;
  }

  function startTimer() {
    setTimerActive(true);
    setShow(false);
  }

  function showCard(currentHour, startHour, endHour, label) {
    console.log(label);
    if (startHour === null || endHour === null) {
      console.log("a");
      return true;
    } else if (currentHour >= startHour && currentHour <= endHour) {
      console.log("b");
      return true;
    } else {
      console.log("c");
      return false;
    }
  }

  function getStatus(action, completed) {
    switch (true) {
      case completed:
        return "ok";
      case action.isDue <= 0: // overdue
        return "overdue";
      case action.isDue < 2: // due in 1 hour
        return "ondeck";
      case action.isDue < 10: // due in several hours
        return "ondeck";
      case action.isDue > 24 && action.scheduleType === "daily":
        return "ok";
      default:
        return "ok";
    }
  }

  function getCardStyle(status, classes) {
    switch (status) {
      case "overdue":
        return classes.overdue;
      case "ondeck":
        return classes.ondeck;
      case "ok":
        return classes.ok;
      default:
        return classes.ok;
    }
  }

  // Queries
  const { data, error, isError, isLoading } = useQuery(
    ["todos", props.filter],
    () => fetchTodos(props.filter, props.domain)
  );

  function handleFilter(val) {
    props.setFilter(val);
    props.setDomains("work");
  }

  Moment.locale("en");

  const mutation = useMutation((formData) => {
    return axios.post(API_URL + "/completions", formData);
  });

  if (isLoading) return "Loading from " + API_URL + "...";
  if (error) return "An error has occurred: " + error.message;

  var today = new Date();
  var currentHour = today.getHours();

  const handleCompletion = (id) => {
    setDone(done.concat([id]));
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + 6000); // 100 mins

  function dueSoon(action) {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 6000); // 100 mins
    return Date.parse(action.nextDue) <= time;
  }

  return (
    <div>
      {timerActive && <Timer id="1" expiryTimestamp={time} />}
      {timerActive && (
        <Button onClick={() => setTimerActive((prev) => !prev)}>
          Exit Focus Mode
        </Button>
      )}
      <Box>
        <h3>{props.filter}</h3>
        <button onClick={() => setShow((prev) => !prev)}>
          {show && "Hide"}
          {!show && "Show"}
        </button>
      </Box>
      {show && (
        <div>
          <List sx={timerActive ? "display:'none'" : "display:'block'"}>
            {data.filter(dueSoon).map((repo) => {
              return (
                <div>
                  {repo.dueToday &&
                    showCard(
                      currentHour,
                      repo.startHour,
                      repo.endHour,
                      repo.label
                    ) && (
                      <ListItem pt={0} key={repo.id}>
                        <Grid justify="space-between" container>
                          <Grid item pr={2}>
                            <CardIcon
                              action={repo}
                              completed={done.includes(repo.id)}
                            />
                          </Grid>
                          <Grid item pr={2}>
                            <Typography>{repo.label}</Typography>
                            {repo.link_url && (
                              <Button
                                size="small"
                                variant="text"
                                onClick={() => {
                                  window.open(repo.link_url);
                                }}
                              >
                                <OpenInNewIcon />
                              </Button>
                            )}
                          </Grid>
                          <Grid item>
                            {/* <DidItButtonGroup
                              id={repo.id}
                              handleCompletion={handleCompletion}
                            /> */}

                            {done.includes(repo.id) &&
                              DONE_MSG[
                                Math.floor(Math.random() * DONE_MSG.length)
                              ]}
                            {done.includes(repo.id) && "+$" + getPoints()}
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                </div>
              );
            })}
          </List>
        </div>
      )}
    </div>
  );
};

export default SimpleList;
