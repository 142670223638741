import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Config from "../ConfigHelper";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

function handleComp(id, scheduled_at) {
  setTimeout(() => {
    axios.patch(
      API_URL + "/instances/" + id + ".json?api=true&key=61-61-61-61&",
      {
        instance: {
          id: id,
          scheduled_at: scheduled_at,
        },
      }
    );
  }, 1000);
}

export default handleComp;
