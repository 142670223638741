import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactTimeAgo from "react-time-ago";
import handleCompletion from "./RescheduleService";
import { DateTimePicker } from "@mui/lab";
import { Grid } from "@mui/material";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [field, setField] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  let handleTextFieldChange = function (e) {
    setField(e.target.value);
  };

  function parseTime(t) {
    var d = new Date();
    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
    d.setMinutes(parseInt(time[2]) || 0);
    return d;
  }

  function save(hour) {
    let t = new Date();

    console.log("now " + t);

    const MS_PER_HOUR = 60 * 60 * 1000;
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const MS_PER_WEEK = MS_PER_DAY * 7;

    console.log(hour);

    if (typeof hour === "string") {
      if (hour.search("h") > -1) {
        console.log("adding hours");
        let hours = hour.match(/(\d{1,})/)[0];
        console.log(hours);
        t.setTime(t.getTime() + hours * MS_PER_HOUR);
      }
      if (hour.search("d") > -1) {
        console.log("adding days");
        let days = hour.match(/(\d{1,})/)[0];
        console.log(days);
        t.setTime(t.getTime() + days * MS_PER_DAY);
      } else if (hour.search("w") > -1) {
        console.log("adding weeks");
        let weeks = hour.match(/(\d{1,})/)[0];
        console.log(weeks);
        t.setTime(t.getTime() + weeks * MS_PER_WEEK);
      }
    }

    // hour of day
    if (typeof hour === "number") {
      t.setHours(hour);
    }

    t.setMinutes(0);

    console.log("update to " + t);

    handleCompletion(props.instance.id, t);
    props.updateAction(props.instance.id, { scheduled_at: t });
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
    handleCompletion(props.instance.id, parseTime(field));
    props.updateAction(props.instance.id, parseTime(field));
  };

  let t = new Date();

  return (
    <div>
      <Button size="small" onClick={handleClickOpen}>
        Reschedule
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reschedule {props.instance.label}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Scheduled <ReactTimeAgo date={props.instance.scheduled_at} />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reschedule to"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleTextFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Grid contnainer>
            <Grid item>
              <Button onClick={handleClose}>Custom Time</Button>
            </Grid>
            {[
              [5, "Early Morning"],
              ["+1h", "+1 hr"],
              ["+2h", "+2 hr"],
              ["+3h", "+3 hr"],
              [7, "Before Breakfast"],
              [9, "After Breakfast"],
              [11, "Before Lunch"],
              [13, "After Lunch"],
              [16, "Before Dinner"],
              [18, "After Dinner"],
              [20, "Before Bed"],
              ["+1d", "Tomorrow"],
              ["+2d", "In 2 days"],
              ["+1w", "Next week"],
            ].map((x) => (
              <Grid item>
                <Button
                  onClick={() => {
                    save(x[0]);
                  }}
                >
                  {x[1]}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
