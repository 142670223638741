import update from "immutability-helper";
import { memo, useCallback, useState } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { Box } from "./Box.js";
import { Dustbin } from "./Dustbin.js";
import { ItemTypes } from "./ItemTypes.js";
import { Grid } from "@mui/material";

export const Container = memo(function Container() {
  const [dustbins, setDustbins] = useState([
    { accepts: [ItemTypes.GLASS], lastDroppedItem: null, name: "8am" },
    { accepts: [ItemTypes.FOOD], lastDroppedItem: null, name: "9am" },
    { accepts: [ItemTypes.GLASS], lastDroppedItem: null, name: "10am" },
    { accepts: [ItemTypes.FOOD], lastDroppedItem: null, name: "11am" },
    { accepts: [ItemTypes.GLASS], lastDroppedItem: null, name: "12pm" },
    {
      accepts: [ItemTypes.PAPER, ItemTypes.GLASS, NativeTypes.URL],
      lastDroppedItem: null,
      name: "10am",
    },
    {
      accepts: [ItemTypes.PAPER, NativeTypes.FILE],
      lastDroppedItem: null,
      name: "11am",
    },
  ]);
  const [boxes] = useState([
    { name: "Workout", type: ItemTypes.GLASS },
    { name: "Prep Dinner", type: ItemTypes.FOOD },
    { name: "Clean Boat", type: ItemTypes.PAPER },
  ]);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }
  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        })
      );
    },
    [droppedBoxNames, dustbins]
  );
  return (
    <Grid container justify="flex-start">
      <Grid item lg={4}>
        <div style={{ overflow: "hidden", clear: "both" }}>
          {boxes.map(({ name, type }, index) => (
            <Box
              name={name}
              type={type}
              isDropped={isDropped(name)}
              key={index}
            />
          ))}
        </div>
      </Grid>
      <Grid item lg={4}>
        <div>
          <div style={{ overflow: "hidden", clear: "both" }}>
            {dustbins.map(({ accepts, lastDroppedItem, name }, index) => (
              <Dustbin
                accept={accepts}
                lastDroppedItem={lastDroppedItem}
                onDrop={(item) => handleDrop(index, item)}
                name={name}
                key={index}
              />
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
});
