import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RocketIcon from "@mui/icons-material/Rocket";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ReactMarkdown from "react-markdown";

import { makeStyles, propsToClassKey } from "@mui/styles";
import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import CheckIcon from "@mui/icons-material/Check";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Config from "../ConfigHelper";
import { getTags } from "../ConfigHelper";
import ImportanceIcon from "./ImportanceIcons";
import handleCompletion from "./CompletionService";

import FormDialog from "./RescheduleDialog.js";

import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import TimerIcon from "@mui/icons-material/Timer";
import { isMobile, isTablet } from "react-device-detect";

import { timeDue } from "../utils";
import ReactTimeAgo from "react-time-ago";
import CardIcon from "./CardIcon";
import SwipeableTemporaryDrawer from "./ActionDetailDrawer";

import Moment from "moment";
import ActionButton from "./ActionButton";
import { LinkOff, PinDropSharp } from "@mui/icons-material";
const API_URL = Config.getApiURL();

function PositionedMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        startIcon={<ArrowDropDownCircleIcon />}
      >
        More
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={props.startTimer}>Start Timer</MenuItem>
        <MenuItem onClick={props.startTimer}>Commit</MenuItem>
        <MenuItem onClick={props.startTimer}>Party</MenuItem>
        <MenuItem onClick={props.startTimer}>Race</MenuItem>
        <MenuItem onClick={props.startTimer}>Zone Out</MenuItem>
        <MenuItem onClick={props.startTimer}>Focus</MenuItem>
        <MenuItem onClick={props.startTimer}>Be Grateful</MenuItem>
        <MenuItem onClick={props.startTimer}>Love yourself</MenuItem>
        <MenuItem onClick={props.startTimer}>Love someone else</MenuItem>
        <MenuItem onClick={props.startTimer}>Earn points $$</MenuItem>
        <Divider />
        <MenuItem
          onClick={function (event) {
            handleCompletion(
              props.action_id,
              false,
              false,
              null,
              props.setCelebrate,
              props.updateAction
            );
            handleClose();
          }}
        >
          Skip
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link to={`/edit-action/${props.action_id}`}>Edit</Link>
        </MenuItem>
        <MenuItem onClick={props.details}>Details</MenuItem>
      </Menu>
    </>
  );
}

function setImportance(id, importance) {
  let data = { axtion: { id: id, importance: importance } };
  return axios.patch(
    API_URL + "/actions/" + id + ".json?api=true&key=61-61-61-61&",
    data
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

function toggleTag(id, initialTags, tag, key = "domain_list") {
  if (initialTags.includes(tag)) {
    const index = initialTags.indexOf(tag);
    if (index > -1) {
      initialTags.splice(index, 1);
    }
    // remove
  } else {
    initialTags.push(tag);
  }
  let data = { axtion: { id: id, [key]: initialTags } };
  return axios.patch(API_URL + "/actions/" + id + ".json", data);
}

function TagMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        startIcon={
          props.field === "requirements_list" ? (
            <StopCircleIcon />
          ) : (
            <LocalOfferIcon />
          )
        }
      >
        {props.label}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {getTags(props.field).map((tag) => (
          <MenuItem
            onClick={function () {
              if (tags.includes(tag)) {
                const filteredTags = tags.filter((item) => item !== tag);
                setTags(filteredTags);
                // remove
              } else {
                setTags(tags.concat(tag));
                console.log("adding " + tag);
                console.log(tags);
              }

              toggleTag(props.id, props.initialTags, tag, props.field);
              //handleClose();
            }}
            tags={tags}
          >
            {tags.includes(tag) ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
            {tag}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function ImportanceMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ImportanceIcon importance={tags[0]} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {[
          "critical",
          "divider",
          "high",
          "medium",
          "low",
          "divider",
          "idea",
          "bonus",
        ].map((tag) =>
          tag === "divider" ? (
            <Divider />
          ) : (
            <MenuItem
              onClick={function () {
                if (tags.includes(tag)) {
                  const filteredTags = tags.filter((item) => item !== tag);
                  setTags(filteredTags);
                  // remove
                } else {
                  setTags([tag]);
                  console.log("adding " + tag);
                  console.log(tags);
                }
                setImportance(props.id, tag);
                // toggleTag(props.id, props.initialTags, tag);
                //handleClose();
              }}
              tags={tags}
            >
              {tags.includes(tag) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <ImportanceIcon importance={tag} />
              {tag}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}

function cardStyles() {
  return {
    critical: {
      borderLeft: "8px solid red",
      // backgroundColor: "rgba(255,255,255,0.85) !important",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },

    ondeck: {
      // borderLeft: "8px solid orange",
      backgroundColor: "rgba(255,255,255,0.85) !important",
      background: "#000",
    },
    overdue: {
      // borderLeft: "8px solid red",
      backgroundColor: "rgba(255,255,255,0.85) !important",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    ok: {
      // borderLeft: "8px solid green",
      backgroundColor: "rgba(255,255,255,0.85) !important",
    },
  };
}

// shouldComponentUpdate(nextProps) {
//   // Rendering the component only if
//   // passed props value is changed
//   if (nextProps.value !== this.props.value) {
//      return true;
//   } else {
//      return false;
//   }
// }

const ActionRow = (props) => {
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = `You clicked ${count} times`;
  // });

  const classes = makeStyles(cardStyles)();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [drawer, setDrawer] = React.useState(false);

  Moment.locale("en");

  const time = new Date();
  time.setSeconds(time.getSeconds() + 6000); // 100 mins

  // props

  // action
  /// id
  /// label
  /// status (completed, doable)
  /// last_completed
  /// completed

  // functions
  /// complete_handler
  /// skip_handler
  /// timer_handler

  // displayOpts
  /// visible - true/false
  /// time due - true/false
  /// icon - true/false
  /// last co

  if (!props.visible) return <></>;

  let now = new Date();
  let scheduled_at = new Date(props.action.scheduled_at);
  let diff = (scheduled_at - now) / 1000 / 3600; // convert to hours

  return (
    <Grid item xs={12} key={props.action.id} className="list">
      <Paper key={props.action.id} variant="outlined">
        <Box
          class={
            diff < 0 &&
            props.action.action.importance === "critical" &&
            classes.critical
          }
        >
          {/* {JSON.stringify(props.action)} */}
          <Grid container p={1}>
            <Grid item xs={2}>
              {props.action.status === "completed" && <CheckBoxIcon />}
              {props.action.status === "scheduled" && (
                <ActionButton
                  id={props.action.id}
                  completed={true}
                  label="Complete"
                  updateAction={props.updateAction}
                  setCelebrate={props.setCelebrate}
                  inner={CheckBoxOutlineBlankIcon}
                >
                  <CheckBoxOutlineBlankIcon />
                </ActionButton>
              )}
              {props.action.status === "skipped" && "skipped"}
            </Grid>
            {/* end left grid */}
            {/* start middle grid */}
            <Grid item xs={8} onClick={handleExpandClick}>
              {props.showTimeDue && (
                <Typography variant="caption">
                  {scheduled_at.toLocaleTimeString(navigator.language, {
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  | <ReactTimeAgo date={props.action.scheduled_at} />
                </Typography>
              )}

              <Typography variant="caption">
                {props.action.estimated_mins &&
                  ` | about ${props.action.estimated_mins} minutes`}
              </Typography>
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      display: {
                        xs: "block", //iphone
                        sm: "block",
                        md: "none", //iPad
                        lg: "block",
                        xl: "block", //macbook
                      },
                    }}
                  >
                    <Typography variant="body2">
                      <ReactMarkdown components={{ p: "span" }}>
                        {props.action.action.label}
                      </ReactMarkdown>
                      <Button
                        onClick={() => props.handleTimer(props.action)}
                        size="small"
                      >
                        <RocketIcon />
                      </Button>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: "none", //iphone
                        sm: "none",
                        md: "block", //iPad
                        lg: "none",
                        xl: "none", //macbook
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <ReactMarkdown components={{ p: "span" }}>
                        {props.action.action.label}
                      </ReactMarkdown>
                      {/* {props.action.importance} */}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {!props.showTimeDue && (
                <Typography variant="body2">
                  {props.action.action.lastCompleted ? (
                    <ReactTimeAgo date={props.action.action.lastCompleted} />
                  ) : (
                    "Not yet"
                  )}
                </Typography>
              )}
            </Grid>
            {/* end middle col */}
            {/* right col */}
            <SwipeableTemporaryDrawer
              drawer={drawer}
              setDrawer={setDrawer}
              currentAction={props.action.action_id}
            />
            <Grid item xs={2} justifyContent="flex-end">
              {props.completed ||
              props.recentlyCompleted ||
              props.action.status === "completed" ? (
                <>
                  <Grid container>
                    <Grid item>
                      {" "}
                      <MonetizationOnIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {props.action.points} points
                      </Typography>
                    </Grid>
                  </Grid>

                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      window.open("https://hopperab.typeform.com/to/g9bo6hSD");
                    }}
                  >
                    +notes
                  </Button>
                </>
              ) : (
                props.showLink &&
                props.action.action.link_url && (
                  <Button
                    component="a"
                    href={
                      isMobile | isTablet && props.action.action.mobile_link_url
                        ? props.action.action.mobile_link_url
                        : props.action.action.link_url
                    }
                    target={isMobile | isTablet ? "self" : "_blank"}
                  >
                    <OpenInNewIcon />
                  </Button>
                )
              )}
            </Grid>

            {/* end col */}
          </Grid>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid item lg={2}></Grid>
              <Grid item xs={10}>
                {props.showTags &&
                  props.action.action.domain_list.map((tag) => (
                    <Chip label={tag} variant="outlined" size="small" />
                  ))}

                {props.showTags &&
                  props.action.action.requirement_list.map((tag) => (
                    <Chip
                      label={tag}
                      color="secondary"
                      variant="outlined"
                      size="small"
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid container p={1}>
              <Grid item lg={2}></Grid>
              <Grid item>
                <Button
                  onClick={() => props.handleTimer(props.action)}
                  size="small"
                >
                  Commit
                </Button>
              </Grid>
              {props.editMode && (
                <>
                  <Grid item>
                    <TagMenu
                      id={props.action_id}
                      initialTags={props.action.action.domain_list}
                      field="domain_list"
                      label="Tag"
                    />
                  </Grid>
                  <Grid item>
                    <TagMenu
                      id={props.action_id}
                      initialTags={props.action.action.requirement_list}
                      field="requirement_list"
                      label="Context"
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                <Button
                  component={Link}
                  size="small"
                  to={`/edit-action/${props.action.action_id}`}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <FormDialog
                  instance={props.action}
                  updateAction={props.updateAction}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={function () {
                    handleExpandClick();
                    handleCompletion(
                      props.action.id,
                      false,
                      false,
                      null,
                      props.setCelebrate,
                      props.updateAction
                    );
                  }}
                  size="small"
                >
                  Skip
                </Button>
              </Grid>
              <Grid item>
                <PositionedMenu
                  details={function (event) {
                    props.setCurrentAction(props.action);
                    setDrawer(true);
                  }}
                  startTimer={() => props.handleTimer(props.action)}
                  action_id={props.action.action_id}
                  instance_id={props.action.id}
                  handleCompletion={() =>
                    handleCompletion(
                      props.action.id,
                      false,
                      false,
                      null,
                      props.setCelebrate,
                      props.updateAction
                    )
                  }
                />
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Paper>
    </Grid>
  );
};

export default React.memo(ActionRow, areEqual);
