function getApiURL() {
  let url;
  if (
    document.location.href.includes("render") ||
    document.location.href.includes("192") ||
    document.location.href.includes("172") ||
    document.location.href.includes("repl")
  ) {
    url = "https://rhythm.hopperlabs.com";
  } else {
    url = "http://localhost:3000";
  }
  console.log(url);
  return url;
}

export const getTags = (key) => {
  if (key === "domain_list")
    return [
      "work",
      "personal",
      "home",
      "riley",
      "ben",
      "jack",
      "lauren",
      "chores",
      "hygiene",
      "mind",
      "body",
      "soul",
      "environment",
      "career",
      "father",
      "fuel",
      "family",
      "finances",
      "friends",
      "community",
      "fitness",
      "growth",
      "rest",
      "work-goal",
      "work-task",
      "morning",
      "morning-early",
      "morning-breakfast",
      "morning-late",
      "evening-before-kids",
      "evening-kids-home",
      "evening-dinner",
      "evening-after-dinner",
      "evening-after-kids-bed",
      "evening-before-bed",
      "evening",
      "meta",
      "admin",
      "rhythm",
      "health",
      "winTheDay",
      "early-afternoon",
      "mid-afternoon",
      "late-afternoon",
      "cleaning",
      "meals",
      "tidy",
      "organize",
      "water",
      "sand",
      "pebble",
      "rock",
      "patient-father",
      "loving-father",
      "engaged-father",
      "active-father",
      "strong-father",
      "brave-father",
      "healthy-father",
      "protector",
      "good-role-model",
      "intelligent-father",
      "virtuous-father",
      "patient-husband",
      "brave-husband",
      "loving-husband",
      "strong-hubsand",
      "supportive-husband",
      "protect-lauren",
      "cherish-lauren",
      "provide-for-lauren",
      "provide-for-my-family",
      "helpful-teammate",
      "responsive-teammate",
      "reliable-teammate",
      "trustworthy-teammate",
      "trustworthy-husband",
      "loving-son",
      "loving-brother",
      "supportive-son",
      "supportive-brother",
      "caring-friend",
      "reliable-friend",
      "responsive-friend",
      "adventurous-friend",
      "not-a-drain-on-society",
      "principled-person",
      "well-read",
      "creative",
      "healthy",
      "resourceful",
      "vibrant",
      "curious",
    ].sort();
  else
    return [
      "computer",
      "home",
      "basement",
      "nokids",
      "sunny-day",
      "kitchen",
      "bedroom",
      "office",
      "yard",
      "errands",
      "daylight",
      "no-interuptions",
      "framingham",
      "errands-sudbury",
      "errands-framingham",
      "boston",
      "sudbury-shopping",
    ].sort();
};

export default { getApiURL };
