import React from "react";
import Box from "@mui/material/Box";
import Config from "../ConfigHelper";
import Grid from "@mui/material/Grid";
import { useParams, Redirect } from "react-router-dom";
import {
  FormRenderer,
  componentTypes,
} from "@data-driven-forms/react-form-renderer";
import {
  componentMapper,
  FormTemplate,
} from "@data-driven-forms/mui-component-mapper";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { ConsoleView } from "react-device-detect";

const API_URL = Config.getApiURL();

// label: "",
// hourDue: "",
// scheduleType: "daily",
// importance: "medium",
// startHour: "",
// endHour: "",
// link_url: "",
// first_prompt_mins: "",
// mandatory: "",
// status: 0,
// notes: "",
// days: "0123456",
// estimated_mins: "",

function ActionForm(props) {
  async function getAction(id) {
    console.log("refetch with filter:  " + id);
    const { data } = await axios.get(API_URL + "/actions/" + id + ".json");
    return data;
  }

  let { id } = useParams();

  const { data, error, isError, isSuccess, isLoading } = useQuery(
    ["action"],
    () => (typeof id !== "undefined" ? getAction(id) : {})
  );

  const mutation = useMutation((formData) => {
    if (formData["axtion"]["id"]) {
      return axios.patch(
        API_URL +
          "/actions/" +
          formData["axtion"]["id"] +
          ".json?api=true&key=61-61-61-61&",
        formData
      );
    } else {
      return axios.post(
        API_URL + "/actions/?api=true&key=61-61-61-61&",
        formData
      );
    }
  });

  let initialVals = "xx";

  if (id) {
    initialVals = { data };
  } else {
    initialVals = {
      axtion: {
        label: "",
        hourDue: "",
        timeDue: "",
        scheduleType: "daily",
        importance: "medium",
        startHour: "",
        endHour: "",
        link_url: "",
        mobile_link_url: "",
        first_prompt_mins: "",
        mandatory: "",
        status: 0,
        notes: "",
        days: "0123456",
        estimated_mins: "",
      },
    };
  }

  if (isLoading) return "Loading from " + API_URL + "...";

  const schema = {
    fields: [
      {
        component: componentTypes.TEXT_FIELD,
        name: "id",
        label: "id",
        hideField: true,
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "label",
        label: "Action",
      },

      {
        component: componentTypes.TEXT_FIELD,
        name: "hourDue",
        label: "Frequency (x hours,  @ x time - when daily, x days, etc)",
      },

      {
        component: componentTypes.TEXT_FIELD,
        name: "timeDue",
        label: "Time of day this task is due in military time (e.g. 0830)",
      },

      {
        component: "select",
        label: "status",
        name: "status",
        simpleValue: true,
        defaultValue: "active",
        options: [
          //@todo - replace with DB vals
          {
            label: "Active",
            value: "active",
          },
          {
            label: "Archived",
            value: "archived",
          },
          {
            label: "Paused",
            value: "paused",
          },
        ],
      },
      {
        component: "select",
        label: "scheduleType",
        name: "scheduleType",
        simpleValue: true,
        options: [
          //[:daily, :hour, :day, :month, :other, :onetime]
          //@todo - replace with DB vals
          {
            label: "each day at",
            value: "daily",
          },
          {
            label: "repeat every x hours",
            value: "hour",
          },
          {
            label: "repeat every x day",
            value: "day",
          },
          {
            label: "repeat every x month",
            value: "month",
          },
          {
            label: "other",
            value: "other",
          },
          {
            label: "onetime action",
            value: "onetime",
          },
        ],
      },
      {
        //:critical, :high, :medium, :low, :idea]
        component: "select",
        label: "importance",
        name: "importance",
        simpleValue: true,
        defaultValue: "idea",
        options: [
          //@todo - replace with DB vals
          {
            label: "Critical",
            value: "critical",
          },
          {
            label: "High",
            value: "high",
          },
          {
            label: "Medium",
            value: "medium",
          },
          {
            label: "Low",
            value: "low",
          },
          {
            label: "Idea",
            value: "idea",
          },
        ],
      },
      {
        component: componentTypes.CHECKBOX,
        name: "mandatory",
        label: "Mandatory",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "startHour",
        label: "Start Hour",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "endHour",
        label: "End Hour",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "expires_after_mins",
        label: "Expires After (mins)",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "days",
        label: "days of week (0=sunday...6=Sat)",
        defaultValue: "0123456",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "link_url",
        label: "Desktop URL",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "mobile_link_url",
        label: "Mobile URL",
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: "estimated_mins",
        label: "estimated_mins",
      },

      {
        component: "select",
        label: "type",
        name: "type",
        simpleValue: true,
        defaultValue: "event",
        options: [
          //@todo - replace with DB vals
          {
            label: "event",
            value: "event",
          },
          {
            label: "reminder",
            value: "reminder",
          },
          {
            label: "task",
            value: "task",
          },
          {
            label: "tracker",
            value: "tracker",
          },
        ],
      },
      {
        //:critical, :high, :medium, :low, :idea]
        component: "select",
        label: "identity",
        name: "identity",
        simpleValue: true,
        defaultValue: "idea",
        //        :father,:husband,:son,:employee,:teammate,:human,:child_of_god,:churchmember,:friend,:familymember
        options: [
          //@todo - replace with DB vals
          {
            label: "father",
            value: "father",
          },
          {
            label: "husband",
            value: "husband",
          },
          {
            label: "son",
            value: "son",
          },
          {
            label: "employee",
            value: "employee",
          },
          {
            label: "teammate",
            value: "teammate",
          },
          {
            label: "human",
            value: "human",
          },
          {
            label: "child_of_god",
            value: "child_of_god",
          },
          {
            label: "churchmember",
            value: "churchmember",
          },
          {
            label: "friend",
            value: "friend",
          },
          {
            label: "familymember",
            value: "familymember",
          },
        ],
      },
      {
        component: componentTypes.TEXTAREA,
        name: "notes",
        label: "notes",
      },
      {
        component: "dual-list-select",
        name: "domain_list",
        options: [
          //@todo - replace with DB vals
          {
            value: "work",
            label: "work",
          },
          {
            value: "home",
            label: "home",
          },
          {
            value: "personal",
            label: "personal",
          },
          {
            value: "riley",
            label: "riley",
          },
          {
            value: "ben",
            label: "ben",
          },
          ,
          {
            value: "jack",
            label: "jack",
          },
          {
            value: "lauren",
            label: "lauren",
          },
        ],
      },
    ],
  };

  if (isError) return "An error has occurred: " + error.message;
  if (mutation.isSuccess) return <Redirect to="/" />;

  return (
    <div>
      {mutation.isLoading ? (
        "Adding action..."
      ) : (
        <>
          {mutation.isError ? (
            <div>An error occurred: {mutation.error.message}</div>
          ) : null}

          {mutation.isSuccess ? <div>Action added!</div> : null}
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <h2>{id ? "Edit Action" : "Add Action"}</h2>
                <FormRenderer
                  schema={schema}
                  componentMapper={componentMapper}
                  FormTemplate={FormTemplate}
                  initialValues={initialVals.data}
                  // onSubmit={(values) => alert(JSON.stringify(values))}
                  onSubmit={(values, actions) => {
                    values = { axtion: values };

                    // console.log(values);
                    // console.log(actions);
                    setTimeout(() => {
                      //JSON.stringify(values);
                      mutation.mutate(values);
                      // alert(JSON.stringify(values, null, 2));
                      // actions.setSubmitting(false);
                    }, 1000);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
}

export default ActionForm;
