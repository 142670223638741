import React, { useEffect, useState, useContext, useCallback } from "react";
import LinkedStateMixin from "react-addons-linked-state-mixin"; // ES6
import ReactDOM from "react-dom";
import Form from "./components/Form";
import axios from "axios";
import List from "./components/List";
import withListLoading from "./components/withListLoading";
import ListFilterControl from "./components/ListFilterControl";
import TimeHorizonControl from "./components/TimeHorizonControl";
import ImportanceControls from "./components/ImportanceControl";
import ScheduleTypeControls from "./components/ScheduleTypeControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import Clock from "react-live-clock";
import ReactFitText from "react-fittext";

import DomainTagsControls from "./components/DomainTagControls";
import ExcludedTagsControl from "./components/ExcludedTagsControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import ExampleTwo from "./example2";

import FilterMenu from "./components/FilterMenu";
import { isMobile } from "react-device-detect";

import { getTags } from "./ConfigHelper";
import {
  green,
  red,
  blue,
  indigo,
  teal,
  cyan,
  deepOrange,
  grey,
  blueGrey,
} from "@mui/material/colors";

import { makeStyles } from "@material-ui/core/styles";

import ListSorter from "./components/ListSorter";
import Example from "./components/example";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SwipeableTemporaryDrawer from "./components/ActionDetailDrawer";
import ResponseDialog from "./components/ResponseDialog";
import ActionButton from "./components/ActionButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LaunchCenter from "./components/LaunchCenter";

import "./styles.css";

import { ActionFilter, ActionStore } from "./components/ActionStore";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Box, Grid, Paper } from "@mui/material";

import APIHelper from "./APIHelper.js";
import Config from "./ConfigHelper";
import Button from "@mui/material/Button";
import ButtonAppBar from "./components/ButtonAppBar";
import ControlledAccordions from "./components/ControlledAccordions";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import MyDataGrid from "./components/MyDataGrid";
import SimpleList from "./components/SimpleList";
import Timer from "./components/Timer";

import { useInterval } from "./utils";
import WantForm from "./components/WantForm";
import ActionForm from "./components/ActionForm";
import ActionDetail from "./components/ActionDetail";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { configure } from "@testing-library/dom";
import { alertTitleClasses } from "@mui/material";
import { DomainVerification, FoodBank } from "@mui/icons-material";
import { dark } from "@mui/material/styles/createPalette";

const styles = {
  // paperContainer: {
  //   backgroundImage: `linear-gradient(120deg, #f6d365 0%, #fda085 100%)`,
  // },
};

const FILTER_MAP = {
  All: () => true,
  Daily: (action) => ["hourly", "daily"].includes(action.scheduleType),
  Other: (action) => ["hour", "week", "month"].includes(action.scheduleType),
};

// https://webgradients.com/
let theme = createTheme();

theme = createTheme(theme, {
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const darkTheme = createTheme({
  ...theme,
  palette: {
    mode: "dark",
    type: "dark",
    primary: {
      main: "#78909c",
      light: "#f44336",
    },
    secondary: {
      main: "#ff5722",
    },
  },
});

const lightTheme = createTheme({
  ...theme,
  palette: {
    type: "light",
    primary: {
      main: "#263238",
    },
    secondary: {
      main: "#ff5722",
    },
  },
});

// export const themeOptions = {
//   palette: {
//     type: "light",
//     primary: {
//       main: "#263238",
//     },
//     secondary: {
//       main: "#ff5722",
//     },
//   },
// };

// theme.palette = {
//   background: {
//     default: "rgba(255,255,255,0.5)",
//     paper: "rgba(255,255,255,0.5)",
//   },
//   primary: "blue",
//   backgroundColor: "blue",
//   color: "blue",
//   // Match [md, ∞)
//   //       [900px, ∞)
//   [theme.breakpoints.up("sm")]: {
//     backgroundColor: "red",
//     color: "red",
//     background: {
//       default: "rgba(255,255,255,0.1)",
//       paper: "rgba(255,255,255,0.1)",
//     },
//   },
// };

// theme.palette.background = {
//   default: "rgba(255,255,255,0.5) !important",
//   paper: "rgba(255,0,255,0.5) !important",
//   [theme.breakpoints.up("md")]: {
//     default: "rgba(100,100,100,0.5) !important",
//     paper: "rgba(255,0,0,0.5) !important",
//   },
// };

theme.typography.fontFamily = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

theme.typography.h6 = {
  fontSize: "0.8rem", //iphone

  "@media (min-width:600px)": {
    fontSize: "2.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9rem",
  },
};

TimeAgo.addDefaultLocale(en);

let colors = [green, red, blue, indigo, teal, cyan, deepOrange];

let color = colors[Math.floor(Math.random() * colors.length)];

function myStyles() {
  return {
    // color: {
    //   color: "white",
    //   backgroundColor: color[500],
    // },
  };
}

function App(props) {
  const [actions, setActions] = useState(["x", "y"]);
  const [isOld, setIsOld] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [instances, setInstances] = useState(["x", "y"]);
  const [sidebar, setSidebar] = useState(false);
  const [done, setDone] = useState([]);
  const [apiEndpoint, setAPIEndpoint] = useState(
    "/instances.json?board=true&level=3&api=true&key=61-61-61-61&"
  );
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [lastUIActivity, setLastUIActivity] = useState(new Date());
  const classes = makeStyles(myStyles)();

  const [filter, setFilter] = useState("All");
  const [loaded, setLoaded] = useState(false);
  const [age, setAge] = useState(0);
  const [celebrate, setCelebrate] = useState(false);

  const [lastBackendUpdate, setLastBackendUpdate] = useState("");
  const [lastLocalUpdate, setLastLocalUpdate] = useState(false);

  const [timerIsRunning, setTimerIsRunning] = useState(false);

  const [refresh, setRefresh] = useState("");

  const [timerActive, setTimerActive] = useState(false);
  const [currentAction, setCurrentAction] = useState(55);
  const [dataStale, setDataStale] = useState(false);

  // filter state vars
  const [threshold, setThreshold] = useState(2);
  const [importance, setImportance] = useState(["critical", "high", "medium"]);
  const [domains, setDomains] = useState([]);
  const [notTaggedWith, setNotTaggedWith] = useState([]);
  const [scheduleType, setScheduleType] = useState([
    "hour",
    "daily",
    "day",
    "month",
    "other",
    "onetime",
  ]);

  const API_URL = Config.getApiURL();

  function updateAction(id, data) {
    // 1. Make a shallow copy of the items
    let items = actions;
    // 2. Make a shallow copy of the item you want to mutate

    let i = actions.findIndex((inst) => inst.id === id);
    let item = items[i];
    // 3. Replace the property you're intested in
    data.completed_at = new Date();

    Object.keys(data).map((key) => {
      item[key] = data[key];
    });

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[i] = item;
    // 5. Set the state to our new copy
    setActions(items);
  }

  function handleThreshold(val) {
    setThreshold(val);
    // setTimeout(() => {
    //   alert(e.target.value);
    //   setThreshold(e.target.value);
    // }, 100);
  }

  const queryClient = new QueryClient();

  async function fetchLastUpdate() {
    const { data } = await axios.get(
      API_URL + "/last_update.json?api=true&key=61-61-61-61&"
    );
    return data;
  }

  function LastUpdate(props) {
    const { data, error, isError, isLoading } = useQuery(["last_update"], () =>
      fetchLastUpdate()
    );

    if (isLoading) return "Loading...";
    if (error) return "Error...";
    props.saveVal(data);
    return <></>;
  }

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        // console.log("scrolling up");
        setLastUIActivity(new Date());
      } else if (y < window.scrollY) {
        // console.log("scrolling down");
        setLastUIActivity(new Date());
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const params = new URLSearchParams({
    threshold: threshold,
    scheduleType: scheduleType,
    importance: importance,
    domains: domains,
    notTaggedWith: notTaggedWith,
  });

  useEffect(() => {
    console.log("filters updated");
    console.log(scheduleType, "- Has changed");
    console.log(
      new URLSearchParams({
        threshold: threshold,
        scheduleType: scheduleType,
        importance: importance,
        domains: domains,
        notTaggedWith: notTaggedWith,
      }).toString()
    );
    setLoaded(false);
    console.log("trigger reload");
  }, [threshold, scheduleType, importance, domains, notTaggedWith]); // <-- here put the parameter to listen

  useInterval(() => {
    // console.log("reloading...");
    setRefresh(refresh + 1);
    // console.log(lastBackendUpdate);
    //console.log(lastLocalUpdate);

    console.log("fetching last update");
    axios
      .get(API_URL + "/last_update.json?api=true&key=61-61-61-61&")
      .then((res) => {
        setLastBackendUpdate(res.data);
      });

    if (!lastLocalUpdate) {
      setLastLocalUpdate(lastBackendUpdate);
      setAge(0);
    } else {
      let remote = new Date(lastBackendUpdate);
      let local = new Date(lastLocalUpdate);
      let age = remote.getTime() - local.getTime(); // unit = ms?
      age = age / 1000;
      setAge(age);
    }

    // let last_completion_time =
    //   done.length > 0
    //     ? done[done.length - 1].time.getTime()
    //     : new Date(1970, 1, 1);
    let last_completion_time = new Date(1970, 1, 1);

    let now = new Date();
    let ui_idle_time = (now.getTime() - last_completion_time) / 1000;
    let scroll_idle_time = (now.getTime() - lastUIActivity) / 1000;

    // console.log("ui_idle_time");
    // console.log(ui_idle_time);

    // console.log("scroll_idle_time");
    // console.log(scroll_idle_time);
    // // @todo wire this up

    // auto reload after 30 seconds drift & 30 seconds of ui inactivity
    let auto_reload = false;
    if (auto_reload && age > 30 && ui_idle_time > 30 && scroll_idle_time > 30) {
      setDataStale(true);
      //setLoaded(false);
      setAge(0);
      // @todo - eliminate duplicate code
      // @todo - and wait a few seconds since last UI action
      // if done.length > 1 && (done[done.length]['time'].getTime() / 1000) > 30 (seconds since epoch)
      //API_URL + "/instances.json?board=true&level=3"
      axios.get(API_URL + apiEndpoint + params.toString()).then((res) => {
        setActions(res.data);
        // store.setActions(res.data);
        setLoaded(true);
        setDataStale(false);
        setLastLocalUpdate(false);
      });
    }
  }, 1000 * 5); // check for updates every 5 seconds

  function handleFilter(val) {
    setFilter(val);
  }

  function setMode(mode) {
    // const [threshold, setThreshold] = useState(2);
    // const [importance, setImportance] = useState(["critical", "high"]);
    // const [domains, setDomains] = useState([]);
    // const [notTaggedWith, setNotTaggedWith] = useState([]);
    // const [scheduleType, setScheduleType] = useState(["hour", "daily"]);

    switch (mode) {
      case "selfcare":
        setThreshold(8);
        setImportance(["critical", "high", "medium", "low", "idea"]);
        setScheduleType(["hour", "daily", "day", "month", "other", "onetime"]);
        setDomains(["fuel", "health", "hygiene", "fitness", "body"]);
        setNotTaggedWith([]);
        break;
      case "work":
        setThreshold(8);
        setImportance(["critical", "high", "medium", "low", "idea"]);
        setScheduleType(["hour", "daily", "day", "month", "other", "onetime"]);
        setDomains(["work"]);
        setNotTaggedWith([]);
        break;
      case "family":
        setThreshold(8);
        setImportance(["critical", "high", "medium", "low", "idea"]);
        setScheduleType(["hour", "daily", "day", "month", "other", "onetime"]);
        setDomains(["lauren", "jack", "ben", "riley"]);
        setNotTaggedWith([]);
        break;
      case "chores":
        setThreshold(8);
        setImportance(["critical", "high", "medium", "low", "idea"]);
        setScheduleType(["hour", "daily", "day", "month", "other", "onetime"]);
        setDomains(["chores", "environment", "home"]);
        setNotTaggedWith([]);
        break;
      case "bonus":
        setThreshold(8);
        setImportance(["low", "medium", "idea"]);
        setScheduleType(["hour", "daily", "day", "month", "other", "onetime"]);
        setDomains([]);
        setNotTaggedWith([]);
        break;
      default:
        setThreshold(2);
        setImportance(["critical", "high", "medium"]);
        setScheduleType(["hour", "daily", "onetime"]);
        setDomains([]);
        setNotTaggedWith([]);
        return "foo";
    }
  }

  let filterFunc = function filter(action) {
    // find tags on the action and in the domains state var array
    let common_tags = action.action.domain_list.filter((tag) =>
      domains.includes(tag.toLowerCase())
    );
    // by default include all actions
    let tag_result = true;

    // if the domains state var filter is set, make sure that the action has the tag specified
    if (domains.length > 0) tag_result = common_tags.length > 0 ? true : false;

    // find actions that do not include tags in the exclusion list
    let excluded_tags = action.action.domain_list.filter((tag) =>
      notTaggedWith.includes(tag.toLowerCase())
    );

    let excluded_tags_result = true;

    if (notTaggedWith.length > 0 && excluded_tags.length > 0)
      excluded_tags_result = false;

    // tag_result = true;
    // excluded_tags_result = true;

    let filter_criteria = {
      threshold: threshold,
      scheduleType: scheduleType,
      importance: importance,
      tags: domains,
      excluded_tags: notTaggedWith,
    };

    console.log(JSON.stringify(filter_criteria));

    let now = new Date();
    let scheduled_at = new Date(action.scheduled_at);
    let diff = (scheduled_at - now) / 1000 / 3600; // ms => seconds => hours

    return (
      diff < threshold &&
      scheduleType.includes(action.action.scheduleType) &&
      importance.includes(action.action.importance) &&
      tag_result &&
      excluded_tags_result
    );
  };

  function changeEndpoint(val) {
    setAPIEndpoint(val);
    setDataStale(true);
    alert(val);
    axios.get(API_URL + apiEndpoint + params.toString()).then((res) => {
      setActions(res.data);
      // store.setActions(res.data);
      setLoaded(true);
      setDataStale(false);
      setLastLocalUpdate(false);
    });
  }

  useEffect(() => {
    if (!loaded) {
      axios.get(API_URL + apiEndpoint + params.toString()).then((res) => {
        setActions(res.data);
        setInstances(res.data);
        // console.log(res.data.length + " actions loaded");
        // store.setActions(res.data);
        setLoaded(true);
        setDataStale(false);
      });
      // axios.get(API_URL + "/instances.json").then((res) => {
      //   setActions(res.data);
      //   // console.log(res.data.length + " actions loaded");
      //   // store.setActions(res.data);
      //   // setLoaded(true);
      //   // setDataStale(false);
      // });
    }
  }, [API_URL, loaded, actions, dataStale]);

  const currentDate = new Date("February 04, 1983");
  const targetDate = new Date("February 04, 2073");
  const today = new Date();
  const lived = (today - currentDate) / (1000 * 60 * 60 * 24);
  const left = (targetDate - today) / (1000 * 60 * 60 * 24);

  var EndpointControl = function (props) {
    // mixins: [React.addons.LinkedStateMixin],

    const [endpoint, setEndpoint] = useState(
      "/instances.json?api=true&key=61-61-61-61&board=true&level=3&"
    );
    const [changed, setChanged] = useState(false);

    const setEndpoint2 = (v) => {
      setEndpoint(v);
      setChanged(true);
    };

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (changed) {
          props.updateEndpoint(endpoint);
          console.log(endpoint);
          setChanged(false);
        }
        // Send Axios request here
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }, [endpoint, props]);

    // const handleChange = (event) => {
    //  // props.updateEndpoint(event.target.value);
    // };

    return (
      <div>
        <TextField
          defaultValue={endpoint}
          onChange={(e) => setEndpoint2(e.target.value)}
          fullWidth
        />
      </div>
    );
  };

  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Container>
          <QueryClientProvider client={queryClient}>
            <div style={styles.paperContainer}>
              {!timerActive && <ButtonAppBar />}
              <Switch>
                <Route path="/add-action">
                  <Box m={2}>
                    <ActionForm />
                  </Box>
                </Route>
                <Route path="/edit-action/:id" component={ActionForm} />
                {/* <Box m={2}>
                  <ActionForm />
                </Box>
              </Route> */}
                <Route path="/action/:id">
                  <Box m={2}>
                    <ActionDetail />
                  </Box>
                </Route>
                <Route path="/add-want">
                  <Box m={2}>
                    <WantForm />
                  </Box>
                </Route>
                <Route path="/likes">
                  <MyDataGrid
                    model="likes"
                    columns={[
                      { field: "id", headerName: "ID", width: 100 },
                      { field: "name", headerName: "Name", width: 200 },
                    ]}
                  />
                </Route>
                <Route path="/actions">
                  <MyDataGrid
                    model="actions"
                    columns={[
                      { field: "id", headerName: "ID", width: 100 },
                      { field: "label", headerName: "Name", width: 200 },
                      { field: "status", headerName: "Status", width: 200 },
                      {
                        field: "actions",
                        headerName: "Actions",
                        width: 200,
                        renderCell: (params) => (
                          <Link to={`/edit-action/${params.id}`}>Edit</Link>
                        ),
                      },
                    ]}
                  />
                </Route>
                <Route path="/sort">
                  <DndProvider backend={HTML5Backend}>
                    {loaded ? <Example actions={actions} /> : "Loading..."}
                  </DndProvider>
                </Route>
                <Route path="/rails">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: "<iframe src='https://rhythm.hopperlabs.com' />",
                    }}
                  />
                </Route>
                <Route path="/plan">
                  <DndProvider backend={HTML5Backend}>
                    <ExampleTwo />
                  </DndProvider>
                </Route>
                <Route path="/wants">
                  <MyDataGrid
                    model="wants"
                    columns={[
                      { field: "id", headerName: "ID", width: 100 },
                      { field: "name", headerName: "Name", width: 200 },
                      { field: "cost", headerName: "Cost", width: 200 },
                      {
                        field: "actions",
                        headerName: "Actions",
                        width: 200,
                        renderCell: (params) => (
                          <Link to={`/edit-want/${params.id}`}>Edit</Link>
                        ),
                      },
                    ]}
                  />
                </Route>
                <Route path="/board">
                  <Box m={2}>
                    <Grid container justify="flex-start">
                      <Grid item lg={4}>
                        <ReactFitText compressor={1}>
                          <h1>
                            <Clock
                              format="HH:mm:ss"
                              interval={1000}
                              ticking={true}
                            />
                          </h1>
                        </ReactFitText>
                      </Grid>
                      <Grid item lg={4}>
                        <ReactFitText compressor={1}>
                          <h1>Day #: {Math.round(lived)}</h1>
                        </ReactFitText>
                      </Grid>
                      <Grid item lg={4}>
                        <ReactFitText compressor={1}>
                          <h1>Estimated left:{Math.round(left)}</h1>
                        </ReactFitText>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} justify="flex-start">
                      <Grid item>
                        <List
                          filter={filter}
                          setTimerActive={setTimerActive}
                          setCurrentAction={setCurrentAction}
                          setCelebrate={setCelebrate}
                          done={done}
                          setDone={setDone}
                          editMode={editMode}
                          status="overdue"
                          heading="Overdue"
                          filterFunc={(action) => {
                            return ["critical"].includes(
                              action.action.importance
                            );
                          }}
                          updateAction={updateAction}
                          loaded={loaded}
                          dataStale={dataStale}
                          actions={actions
                            .filter((action) =>
                              ["scheduled", "rescheduled"].includes(
                                action.status
                              )
                            )
                            .filter((action) => {
                              let now = new Date();
                              let scheduled_at = new Date(action.scheduled_at);
                              let diff = (now - scheduled_at) / 1000 / 3600; // convert to hours
                              if (diff > 0) return true;
                            })}
                          {...props}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Route>
                <Route path="/">
                  {timerActive && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          minHeight: "100vh",
                        }}
                        className={classes.color}
                      >
                        <Grid
                          item
                          sx={{
                            display: {
                              xs: "none",
                              md: "block",
                            },
                          }}
                        >
                          <Typography variant="h1">
                            {currentAction.action.label}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: {
                              xs: "block",
                              sm: "none",
                            },
                          }}
                        >
                          <Typography variant="h3">
                            {currentAction.action.label}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Timer
                            id={currentAction.id}
                            minutes="20"
                            setTimeElapsed={setTimeElapsed}
                          />
                        </Grid>
                        <Grid item pt={4}>
                          <Grid container pt={4}>
                            <Grid item p={2}>
                              {currentAction.action.link_url && (
                                <Button
                                  variant="outlined"
                                  size="large"
                                  style={{ backgroundColor: "white" }}
                                  href={currentAction.action.link_url}
                                  target="_blank"
                                >
                                  Open Link
                                </Button>
                              )}
                            </Grid>
                            <Grid item p={2}>
                              <Button
                                style={{ backgroundColor: "white" }}
                                size="large"
                                variant="outlined"
                                onClick={() => setTimerActive((prev) => !prev)}
                              >
                                Give Up
                              </Button>
                            </Grid>
                            <Grid item p={2}>
                              <ActionButton
                                id={currentAction.id}
                                style={{ backgroundColor: "white" }}
                                completed={true}
                                type="text"
                                label="Complete"
                                size="large"
                                variant="outlined"
                                setDone={setDone}
                                duration={timeElapsed}
                                setCelebrate={setCelebrate}
                                updateAction={updateAction}
                                done={done}
                                inner="Finish"
                                startIcon={<CheckBoxOutlineBlankIcon />}
                                extraFunc={() =>
                                  setTimerActive((prev) => !prev)
                                }
                              >
                                Finish
                              </ActionButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {!timerActive && (
                    <Box m={2}>
                      <Grid container justify="flex-start">
                        <Grid item lg={4} sm={12}>
                          <ReactFitText compressor={1}>
                            <h1>
                              <Clock
                                format="HH:mm:ss"
                                interval={1000}
                                ticking={true}
                              />
                            </h1>
                          </ReactFitText>
                        </Grid>
                        <Grid item lg={4} sm={12}>
                          <ReactFitText compressor={1}>
                            <h1>Day #: {Math.round(lived)}</h1>
                          </ReactFitText>
                        </Grid>
                        <Grid item lg={4} sm={12}>
                          <ReactFitText compressor={1}>
                            <h1>Estimated left:{Math.round(left)}</h1>
                          </ReactFitText>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} lg={12}>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("default")}
                          >
                            Default
                          </Button>

                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("selfcare")}
                          >
                            Selfcare
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("bonus")}
                          >
                            Bonus
                          </Button>

                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("work")}
                          >
                            Work
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("family")}
                          >
                            Family
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setMode("chores")}
                          >
                            Chores
                          </Button>

                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setSidebar((prev) => !prev)}
                          >
                            {sidebar ? "Less" : "More"}
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setEditMode((prev) => !prev)}
                          >
                            {editMode ? "Leave Edit Mode" : "Enter Edit Mode"}
                          </Button>

                          {/* <EndpointControl updateEndpoint={changeEndpoint} />
                           */}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={10}
                          order={{ xs: 1, sm: 1 }}
                        >
                          {sidebar && (
                            <Box
                              sx={{
                                display: {
                                  xs: "block",
                                  md: "block",
                                  lg: "block",
                                },
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    initialTags={[threshold]}
                                    menuOptions={[1, 2, 4, 8, 24, 48, 72, 168]}
                                    {...props}
                                    setTags={handleThreshold}
                                    text_button="Time Horizon"
                                    max_opts="1"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    menuOptions={[
                                      "critical",
                                      "high",
                                      "medium",
                                      "low",
                                      "idea",
                                    ]}
                                    initialTags={importance}
                                    setTags={setImportance}
                                    text_button="Priority"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    initialTags={scheduleType}
                                    menuOptions={[
                                      "hour",
                                      "daily",
                                      "day",
                                      "month",
                                      "other",
                                      "onetime",
                                    ]}
                                    setTags={setScheduleType}
                                    text_button="Recurrence"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    initialTags={[]}
                                    menuOptions={getTags("domain_list")}
                                    setTags={setDomains}
                                    text_button="Tags"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    initialTags={[]}
                                    menuOptions={getTags("domain_list")}
                                    setTags={setNotTaggedWith}
                                    text_button="Exclude"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <FilterMenu
                                    initialTags={[]}
                                    menuOptions={getTags()}
                                    setTags={() =>
                                      alert("not implemented @todo")
                                    }
                                    text_button="Context"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={sidebar ? 3 : 4}
                          order={{ xs: 3, sm: 2 }}
                        >
                          <List
                            filter={filter}
                            setTimerActive={setTimerActive}
                            setCelebrate={setCelebrate}
                            setCurrentAction={setCurrentAction}
                            status="ondeck"
                            heading="Upcoming"
                            done={done}
                            setDone={setDone}
                            filterFunc={filterFunc}
                            editMode={editMode}
                            updateAction={updateAction}
                            loaded={loaded}
                            dataStale={dataStale}
                            actions={actions
                              .filter((action) =>
                                ["scheduled", "rescheduled"].includes(
                                  action.status
                                )
                              )
                              .filter((action) => {
                                let now = new Date();
                                let scheduled_at = new Date(
                                  action.scheduled_at
                                );
                                let diff = (now - scheduled_at) / 1000 / 3600; // convert to hours
                                if (diff < 0) return true;
                              })}
                            {...props}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={sidebar ? 3 : 4}
                          order={{ xs: 2, sm: 3 }}
                        >
                          <List
                            filter={filter}
                            setTimerActive={setTimerActive}
                            setCurrentAction={setCurrentAction}
                            setCelebrate={setCelebrate}
                            done={done}
                            setDone={setDone}
                            editMode={editMode}
                            status="overdue"
                            heading="Overdue"
                            filterFunc={filterFunc}
                            updateAction={updateAction}
                            loaded={loaded}
                            dataStale={dataStale}
                            actions={actions
                              .filter((action) =>
                                ["scheduled", "rescheduled"].includes(
                                  action.status
                                )
                              )
                              .filter((action) => {
                                let now = new Date();
                                let scheduled_at = new Date(
                                  action.scheduled_at
                                );
                                let diff = (now - scheduled_at) / 1000 / 3600; // convert to hours
                                if (diff > 0) return true;
                              })}
                            {...props}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={sidebar ? 3 : 4}
                          order={{ xs: 4, sm: 4 }}
                        >
                          <List
                            filter={filter}
                            setTimerActive={setTimerActive}
                            setCurrentAction={setCurrentAction}
                            setCelebrate={setCelebrate}
                            done={done}
                            setDone={setDone}
                            editMode={editMode}
                            updateAction={updateAction}
                            status="ok"
                            heading="Done"
                            filterFunc={filterFunc}
                            loaded={loaded}
                            dataStale={dataStale}
                            actions={actions.filter((action) =>
                              ["completed", "skipped"].includes(action.status)
                            )}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  <SwipeableTemporaryDrawer currentAction={currentAction} />
                  <ResponseDialog
                    open={celebrate}
                    setCelebrate={setCelebrate}
                    handleSkip={() => alert("not implemented")}
                  />
                  {/* <LastUpdate saveVal={setLastBackendUpdate} time={refresh} /> */}

                  <div>
                    {/* {lastBackendUpdate}
                  {lastLocalUpdate} */}
                    <Typography>Drift: {age} seconds</Typography>

                    <Button onClick={() => setLoaded(false)}>Refresh</Button>
                  </div>

                  {params.toString()}
                </Route>
              </Switch>
              {/* end content */}
            </div>
          </QueryClientProvider>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;

ReactDOM.render(<App />, document.getElementById("root"));
