import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Config from "../ConfigHelper";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

function ActionButton(props) {
  const mutation = useMutation((formData) => {
    return axios.patch(
      API_URL +
        "/instances/" +
        formData["instance"]["id"] +
        ".json?api=true&key=61-61-61-61&",
      formData
    );
  });

  let style = props.style ? props.style : {};
  let size = props.size ? props.size : "small";
  let variant = props.variant ? props.variant : "text";

  function save(id, completed) {
    const time = new Date();
    let notes = "foo";
    let duration = props.duration;
    if (typeof props.extraFunc == "function") {
      props.extraFunc();
    }

    setTimeout(() => {
      mutation.mutate({
        instance: {
          id: id,
          status: completed ? "completed" : "skipped", //completed,
          notes: notes,
          duration: duration,
        },
      });
    }, 100);
  }

  function handleComp(id, completed) {
    if (completed === true) {
      props.updateAction(id, { status: "completed" });
      //props.setCelebrate(true);
    } else props.updateAction(id, { status: "skipped" });

    save(id, completed);

    setTimeout(() => {
      props.setCelebrate(false);
    }, 1000); //ms
  }

  if (mutation.isLoading)
    return (
      <Button>
        <CircularProgress />
      </Button>
    );

  if (mutation.isError)
    return <div>An error occurred: {mutation.error.message}</div>;

  if (props.isDone || mutation.isSuccess) {
    return (
      <Button>
        <CheckBoxOutlinedIcon />
      </Button>
    );
  }

  const Inner = props.inner; // Note: variable name _must_ start with a capital letter

  if (props.type === "text") {
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
      >
        {props.label}
      </Button>
    );
  } else if (props.startIcon)
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
        startIcon={props.startIcon}
      >
        <Inner></Inner>
      </Button>
    );
  else
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
      >
        <Inner>xxx</Inner>
      </Button>
    );
}

export default ActionButton;
