import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Config from "../ConfigHelper";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

const POINTS = ["2", "5", "10", "100", "1000"];

function getPoints() {
  return POINTS[Math.floor(Math.random() * POINTS.length)];
}

function handleComp(
  id,
  completed,
  extraFunc,
  durationArg,
  setCelebrate,
  updateAction
) {
  const time = new Date();
  let notes = "foo";
  let duration = durationArg;
  if (typeof extraFunc == "function") {
    extraFunc();
  }

  if (completed === true) {
    updateAction(id, { status: "completed" });
  } else updateAction(id, { status: "skipped" });

  //if (completed) setCelebrate(true);

  setTimeout(() => {
    axios.patch(
      API_URL + "/instances/" + id + ".json?api=true&key=61-61-61-61&",
      {
        instance: {
          id: id,
          status: completed ? "completed" : "skipped", //completed,
          notes: notes,
          duration: duration,
        },
      }
    );
  }, 1000);

  if (completed) {
    setTimeout(() => {
      setCelebrate(false);
    }, 1000); //ms
  }
}

export default handleComp;
