import { useState, useCallback, useEffect } from "react";
import { Card } from "./DndCard";
import update from "immutability-helper";

import Config from "../ConfigHelper";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import axios from "axios";

const API_URL = Config.getApiURL();

const style = {
  width: 200,
};

export const Container = (props) => {
  {
    const [cards, setCards] = useState([
      {
        id: 1,
        text: "Write a cool JS library",
      },
      {
        id: 2,
        text: "Make it generic enough",
      },
      {
        id: 3,
        text: "Write README",
      },
      {
        id: 4,
        text: "Create some examples",
      },
      {
        id: 5,
        text: "Spam in Twitter and IRC to promote it (note that this element is taller than the others)",
      },
      {
        id: 6,
        text: "???",
      },
      {
        id: 7,
        text: "PROFITx",
      },
    ]);

    useEffect(() => {
      setCards(props.actions);
    }, []);

    const mutation = useMutation((data) => {
      return axios.patch(
        API_URL +
          "/actions/" +
          data["axtion"]["id"] +
          ".json?api=true&key=61-61-61-61&",
        data
      );
    });

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        // alert("moved" + dragIndex);
        // alert("hover" + hoverIndex);
        mutation.mutate({
          axtion: { id: dragCard.id, position: hoverIndex },
        });
        console.log(
          "mutate: move id " + dragCard.id + " to position " + hoverIndex
        );
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      },
      [cards]
    );
    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.label}
          moveCard={moveCard}
        />
      );
    };

    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};
