import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Config from "../ConfigHelper";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

const queryClient = new QueryClient();

const MyDataGrid = (props) => {
  // // const [repos, setRepos] = useState(props.repos);
  // const [filter, setFilter] = useState("All");

  const queryClient = useQueryClient();

  // Queries
  const { data, error, isError, isLoading } = useQuery(props.model, () =>
    fetch(API_URL + "/" + props.model + ".json?api=true&key=61-61-61-61&").then(
      (res) => res.json()
    )
  );

  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
          }}
          rows={data}
          columns={props.columns}
          pageSize={25}
          rowsPerPageOptions={[25]}
          checkboxSelection
        />
      </div>
    </div>
  );
};

export default MyDataGrid;
