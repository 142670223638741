import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Toolbar,
  Box,
  Button,
  AppBar,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import green from "@mui/material/colors/green";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { NoEncryption } from "@mui/icons-material";

// react.school/material-ui

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "#fff",
    textDecoration: "none",
  },
  customColor: {
    // or hex code, this is normal CSS background-color
    backgroundColor: green[500],
  },
  customHeight: {
    minHeight: 200,
  },
  offset: theme.mixins.toolbar,
}));

function TagMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let links = [];

  links.push({ name: "Open Things", url: "things:///" });
  links.push({ name: "Add Thing", url: "things:///add?show-quick-entry=true" });
  links.push({ name: "env local", url: "http://192.168.86.22:3001" });
  links.push({ name: "rhythm-dev", url: "https://rhythm-dev.onrender.com" });
  links.push({ name: "prod", url: "https://pairs.onrender.com" });

  return (
    <div>
      {/** id
String. The ID of an area, project, tag or to-do to show; or one of the following built-in list IDs: inbox, today, anytime, upcoming, someday, logbook, tomorrow, deadlines, repeating, all-projects, logged-projects. Takes precedence over query.*/}
      <Button
        color="inherit"
        component={Link}
        to={{
          pathname: "things:///show?id=anytime&filter=honeydo",
        }}
        target="_blank"
      >
        Honeydo
      </Button>
      <Button
        color="inherit"
        component={Link}
        to={{
          pathname:
            "https://docs.google.com/spreadsheets/d/13xhNuzOaBc504po8zdvx2j7JR9DF34dyHNB6he3Iqs8/edit",
        }}
        target="_blank"
      >
        Meal Log
      </Button>
      <Button
        color="inherit"
        component={Link}
        to={{
          pathname:
            "https://docs.google.com/document/d/13J5aoGC8SZF8Opg5L7HwTDe3_AflX8CG7zHmc5ncqrU/edit",
        }}
        target="_blank"
      >
        Goals
      </Button>

      <Button
        color="inherit"
        component={Link}
        to={{
          pathname:
            "https://docs.google.com/document/d/1bl18XB-YjUvNhGDr540ah_pJYiXq1k-i3JnQ9fEIvc4/edit",
        }}
        target="_blank"
      >
        Work goals
      </Button>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {["add-want", "add-action"].map((tag) => (
          <MenuItem to={`/${tag}`} component={Link}>
            {tag}
          </MenuItem>
        ))}
        {links.map((link) => (
          <MenuItem>
            <a href={link.url}>{link.name}</a>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [example, setExample] = useState("primary");
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const isCustomColor = example === "customColor";
  const isCustomHeight = example === "customHeight";

  let wallDisplay = false;

  if (document.location.href.includes("/pi")) {
    wallDisplay = true;
  }

  return (
    <React.Fragment>
      <AppBar
        color={isCustomColor || isCustomHeight ? "primary" : example}
        className={`${isCustomColor && classes.customColor} ${
          isCustomHeight && classes.customHeight
        }`}
      >
        <Toolbar>
          {/* <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}
          sx={{ mr: 2, display: { xs: 'block', sm: 'none',}, }}>   
          <MenuIcon />
        </IconButton> */}

          {!wallDisplay && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <Typography variant="h6" className={classes.title}>
                Rhythm
              </Typography>
            </IconButton>
          )}
          <Link to="/add-action" className={classes.title}>
            <Typography variant="h6" className={classes.title}></Typography>
          </Link>
          {/* <Box p={1}>
            <Button color="inherit" to="/edit-actions" component={Link}>
              Edit Actions
            </Button>
          </Box> */}
          {!wallDisplay && (
            <>
              <TagMenu />
              {/* <Button color="inherit" to="/add-want" component={Link}>
                +Reward
              </Button>
              <Button color="inherit" to="/add-action" component={Link}>
                +Action
              </Button> */}
            </>
          )}
          <Drawer
            anchor="left" //from which side the drawer slides in
            variant="temporary" //if and how easily the drawer can be closed
            open={open} //if open is true, drawer is shown
            onClose={toggleDrawer(false)} //function that is called when the drawer should close
            onOpen={toggleDrawer(true)} //function that is called when the drawer should open
          >
            <Box>
              {/* The inside of the drawer */}
              <Box p={1}>
                <Button color="inherit" to="/" component={Link}>
                  Dashboard
                </Button>
              </Box>

              <Box p={1}>
                <Button color="inherit" to="/wants" component={Link}>
                  Rewards
                </Button>
              </Box>
              <Box p={1}>
                <Button color="inherit" to="/actions" component={Link}>
                  Actions
                </Button>
              </Box>
              <Box p={1}>
                <Button color="inherit" to="/likes" component={Link}>
                  Likes
                </Button>
              </Box>
              <Box p={1}>
                <Button color="inherit" to="/sort" component={Link}>
                  Sort Actions
                </Button>
              </Box>
              <Box p={1}>
                <Button color="inherit" to="/rails" component={Link}>
                  Rails App
                </Button>
              </Box>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* <Typography>
        This content doesn't appear below the AppBar because we added an
        additional Toolbar component above, this is the recommended approach.{" "}
      </Typography>
      <Typography>
        Change the AppBar example by clicking on one of the numbers above.
      </Typography> */}
      {/* <Typography>
        <ul>
          <li> 1: color: default </li>
          <li> 2: color: primary </li>
          <li> 3: color: secondary </li>
          <li> 4: color: transparent </li>
          <li> 5: custom color class </li>
          <li> 6: custom height class </li>
        </ul>
      </Typography> */}
    </React.Fragment>
  );
}
